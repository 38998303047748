 .header-container {
    background: var(--primary-gradient);
    padding-top: 1em;
    padding-bottom: 1em;
 }

 .home-header-container {
  background: var(--primary-gradient);
  padding: 0 !important;
 }

 .header-background {
  background-image: url('/src/images/header-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40em;
 }

 .bottom-header-row {
  margin-top: 15em !important;
 }

 .sign-up-button {
  background: var(--accent) !important;
  color: white !important;
  border: none;
  padding: .5em 2em !important;
  border-radius: 8px !important;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 5em;
  margin-right: 1em;
 }

 .central-headers-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .header-link {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none; /* Remove any borders */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  font-weight: 500;
  color: white;
  white-space: pre-wrap;
  text-align: center;
 }
 .login-register-container {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
 }

.footer {
  background: var(--primary-gradient);
  color: white;
}

.footer-container {
  position: relative;
}

@media screen and (max-width: 768px) {
  .header-background {
    height: 30em;
  }
  .bottom-header-row {
    margin-top: 10em !important;
  }
  .header-container {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .footer {
    padding: 1em;
  }
  .footer-container {
    padding: 1em;
  }
  .central-headers-container {
    flex-direction: column;
    align-items: center;
  }
  .login-register-container {
    margin-top: 1em;
  }
  .header-link {
    margin: 1em;
  }

  .sign-up-button {
    margin-top: 2em;
    background-color: var(--accent) !important;
    color: white !important;
  }
}
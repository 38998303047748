.home-page-container {
  width: 100%;
  background: var(--bg);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
}

.home-section-container {
  width: 80%;
  border: 3px solid var(--primary);
  border-radius: 12px;
  margin: 1em !important; 
  padding: 3em;
  background: white;
}

.upcoming-events-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-y: hidden;
}

.upcoming-event-container {
  min-width: 20vw !important;
  min-height: 20vh !important;
  background: var(--primary-gradient);
  border-radius: 8px;
  color: white;
  margin: .25em;
  padding: .5em;
}

@media (max-width: 768px) {
  .upcoming-event-container {
    min-width: 40vw !important;
    min-height: 20vh !important;
    background: var(--primary-gradient);
    border-radius: 8px;
    color: white;
    margin: .25em;
    padding: .5em;
  }

  .home-section-container {
    width: 90%;
    padding: 1em;
  }
}

.details-button {
  background: white !important;
  color: var(--primary) !important;
  border: none !important;
  border-radius: 4px !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.details-button:hover {
  background: var(--primary) !important;
  color: white !important;
}
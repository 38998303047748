
:root {
  --primary: #044983;
  --bg: #0044880a;
  --primary-gradient: linear-gradient(90deg, #044983, #00A8A8);
  --secondary: #00A8A8;
  --accent: #990000;
  --c-green: #006600;
  --c-green-light: #00660023;
  --c-orange: #FFA500;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%; /* Add this line to make the root element wider */
}

* {
  font-family: 'Roboto', sans-serif;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  background-color: var(--bg);
  min-height: 100%;
  position: absolute;
  width: 100%;
}

.flex-container {
  display: flex;
}

.margin-bottom-1 {
  margin-bottom: 1em !important;
}

.margin-top-d5 {
  margin-top: .5em !important;
}

.margin-top-1 {
  margin-top: 1em !important;
}

.margin-top-2 {
  margin-top: 2em !important;
}

.margin-top-5 {
  margin-top: 5em !important;
}

.margin-left-1 {
  margin-left: 1em;
}

.margin-left-d5 {
  margin-left: .5em;
}

.margin-right-d5 {
  margin-right: .5em;
}

.margin-right-1 {
  margin-right: 1em;
}

.margin-bottom-d5 {
  margin-bottom: .5em;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--secondary);
}

.border-bottom-dark-1 {
  border-bottom: 1px solid var(--primary);
}

.border-bottom-white-1 {
  border-bottom: 1px solid white;
}

.border-top-1 {
  border-top: 1px solid var(--secondary);
}

.border-right-1 {
  border-right: 1px solid var(--secondary);
}

.border-right-dark-1 {
  border-right: 1px solid var(--primary);
}

.border-1 {
  border: 1px solid var(--secondary) !important;
}

.border-1-black {
  border: 1px solid black !important;
}

.border-1-gray {
  border: 1px solid gray !important;
}

.no-border {
  border: none !important;
}

.margin-1 {
  margin: 1em !important;
}

.padding-1 {
  padding: 1em !important;
}

.padding-d5 {
  padding: .5em !important;
}

.padding-bottom-1 {
  padding-bottom: 1em;
}

.padding-bottom-d5 {
  padding-bottom: .5em;
}

.padding-top-1 {
  padding-top: 1em;
}

.padding-top-d5 {
  padding-top: .5em;
}

.padding-2 {
  padding: 2em !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.primary {
  background: var(--primary) !important;
}

.primary-gradient {
  background: var(--primary-gradient) !important;
}

.primary-color {
  color: var(--primary) !important;
}

.secondary {
  background-color: var(--secondary) !important;
}

.secondary-color {
  color: var(--secondary) !important;
}

.accent {
  background-color: var(--accent) !important;
}

.accent-color {
  color: var(--accent) !important;
}

.bg {
  background-color: var(--bg) !important;
}

.transparent {
  background-color: transparent !important;
}

.white{
  color: white !important;
}

.white-bg{
  background-color: white !important;
}

.rounded-padded {
  border-radius: 10px;
  padding: .5em;
}

.center-content-h {
  display: flex !important;
  justify-content: center !important;
}

.center-content-v {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.center-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.left-content {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.right-content {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.pull-down {
  align-self: flex-end;
}

.xxl-text {
  font-size: 36px !important;
}

.large-text {
  font-size: 24px !important;
}

.medium-text {
  font-size: 20px !important;
}

.small-text {
  font-size: 16px !important;
}

@media screen and (max-width: 768px) {
  .xxl-text {
    font-size: 24px !important;
  }
  
  .large-text {
    font-size: 18px !important;
  }
  
  .medium-text {
    font-size: 14px !important;
  }
  
  .small-text {
    font-size: 10px !important;
  }
}

.extra-bold {
  font-weight: 700 !important;
}

.bold {
  font-weight: 500 !important;
}

.loading-page-container {
  height: 90vh;
  color: var(--primary);
  width: 100%;
}

.big-spinner {
  height: 5em !important;
  width: 5em !important;
}

.center-content-text-table th {
  text-align: center;
}

.center-content-text-table td {
  text-align: center;
}

.turn-primary-on-hover:hover {
  background: var(--primary-gradient) !important;
  color: white !important;
}

.keep-identation {
  white-space: pre-wrap;
}

.full-height {
  height: 100%;
}

.logo {
  width: auto;
  height: 10vh;
  object-fit: contain;
}

.text-align-center {
  text-align: center !important;
}

.full-width {
  width: 100% !important;
}

.red-border {
  border: 1px solid red !important;
}

@media screen and (max-width: 768px) {
  .logo {
    width: auto;
    height: 7vh;
    object-fit: contain;
  }
}